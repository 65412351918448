import { useStoryblok, StoryblokComponent, storyblokEditable } from "@storyblok/react";
import ReactGA from 'react-ga4';

import './_app.scss';
import { useState, useEffect } from 'react';
import Header from './components/Header/Header';
import Footer from "./components/Footer/Footer";
import MobileHeader from "./components/MobileHeader/MobileHeader";
import MobileFooter from "./components/MobileFooter/MobileFooter";
import VideoModal from "./components/VideoModal/VideoModal";

ReactGA.initialize('G-NLHBE052X0')

// landing


function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

function getScrollbarWidth() {
  // Creating invisible container
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement('div');
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

  // Removing temporary elements from the DOM
  outer.parentNode.removeChild(outer);

  return scrollbarWidth;
}

function App() {
  const basename = '/';
  const pages = ["About", "Work", "Clients", "Sketches", "Talks-Events"];
  // const pages = ["About", "Work", "Clients", "Sketches", "Talks-Events", "Contact"];
  const [isOpen, setIsOpen] = useState(false);

  const temp = window.location.pathname;
  let slug = temp.replace(basename, '');
  const mappedPages = pages.map(page => page.toLowerCase());
  if (!mappedPages.includes(slug)) {
    slug = "";
  };
  // const path = slug === "" ? "landing" : slug;
  const path = slug === "" ? "about" : slug;
  const story = useStoryblok(path, {});

  // Breakpoints
  const size = useWindowSize();
  const isMobile = size.width <= 650;
  const isSingleColumn = size.width <= 900;
  const scroll_width = getScrollbarWidth();

  // While the data is loading, display header
  if ((!story || !story.content) && !isMobile) {
    return <Header
      basename={basename}
      page={path}
      pages={pages}
    />;
  } else if ((!story || !story.content) && isMobile) {
    return <MobileHeader
      basename={basename}
      page={path}
      pages={pages}
      isMobile={isMobile}
    />;
  }

  // Open / close modal
  const handleModalChange = (val) => {
    setIsOpen(val);
  };

  return (
    <>
      {isOpen && <VideoModal handleModalChange={handleModalChange} item={isOpen} />}
      <div {...storyblokEditable(story.content)} key={story.content._uid} className="app" id="app-container" style={path === "work" ? { opacity: isOpen ? .5 : 1, overflow: 'hidden' } : { opacity: isOpen ? .5 : 1, overflow: 'auto' }} >
        {isMobile ?
          <MobileHeader
            basename={basename}
            page={path}
            pages={pages}
            isMobile={isMobile}
          />
          :
          <Header
            basename={basename}
            page={path}
            pages={pages}
          />
        }
        <StoryblokComponent blok={story.content} key={story.content._uid} isMobile={isMobile} size={size} isSingleColumn={isSingleColumn} handleModalChange={handleModalChange} />
        {isMobile ?
          <MobileFooter
            scrollWidth={scroll_width}
            page={path}
            basename={basename}
          />
          :
          <Footer
            scrollWidth={scroll_width}
            page={path}
            basename={basename}
          />}
      </div>
    </>

  );
}

export default App;
