import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import LandingPage from './pages/LandingPage';
import AboutPage from './pages/AboutPage';
import { storyblokInit, apiPlugin } from "@storyblok/react";
import WorkPage from './pages/WorkPage';
import ClientsPage from './pages/ClientsPage';
import ContactPage from './pages/ContactPage';
import SketchesPage from './pages/SketchesPage';
import LecturesPage from './pages/LecturesPage';

// initialize Storyblok, connecting components to their "story" in the cms
storyblokInit({
  accessToken: "LQaouz9DfIte6BfZa4wRYwtt",
  use: [apiPlugin],
  components: {
    "about-page": AboutPage,
    // "landing-page": LandingPage,
    "work-page": WorkPage,
    "clients-page": ClientsPage,
    // "contact-page": ContactPage,
    "sketches-page": SketchesPage,
    "talks-events-page": LecturesPage
  },
  apiOptions: {
    region: "us",
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
